import { Button } from '@findigs/ui-kit/src/components/button';
import { useCallback } from 'react';
import styled from 'styled-components';
import type { UseRemovePhotoModalState } from '../hooks/use-remove-photo-modal';
import ConfirmModalLayout from '../layouts/confirm-modal-layout';
import type { ModalProps } from '../state/modal';
import RemoveIcon from '../images/modals/remove-photo-icon.inline.svg';

export interface RemovePhotoModalProps extends ModalProps {
  options: UseRemovePhotoModalState['options'];
}

const StyledConfirmModalLayout = styled(ConfirmModalLayout)`
  /** 768px - tablet **/
  @media (min-width: 48rem) {
    max-width: 28.875rem;

    .cancel {
      flex-basis: 11.4375rem;
    }
  }
`;

const RemovePhotoModal = ({
  options: { onConfirm },
  hide,
}: RemovePhotoModalProps) => {
  const _onConfirm = useCallback(() => {
    hide();
    if (onConfirm) {
      onConfirm();
    }
  }, [hide, onConfirm]);

  const Confirm = (
    <Button type="button" variant="FilledStandard" onClick={_onConfirm}>
      Yes, remove photo
    </Button>
  );
  const Cancel = (
    <Button type="button" variant="Tonal" onClick={hide} className="cancel">
      Cancel
    </Button>
  );

  return (
    <StyledConfirmModalLayout
      onDismiss={hide}
      icon={<RemoveIcon aria-hidden={true} />}
      actions={[Confirm, Cancel]}
    >
      <h2 className="text-t-sm mb-8">Remove this photo?</h2>
      <p className="text-b-md-regular text-muted">
        You&apos;ll need to upload a new photo.
      </p>
    </StyledConfirmModalLayout>
  );
};

export default RemovePhotoModal;
